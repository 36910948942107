/*eslint-disable*/
import React, { useState } from 'react';
// nodejs library to set properties for components

// nodejs library that concatenates classes

// material-ui core components
import { List, ListItem } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

import Grid from "@material-ui/core/Grid"


import Facebook from "../../assets/facebook.svg"
import Twitter from "../../assets/twitter.svg"
import Instagram from "../../assets/instagram.svg"
import GitHub from "../../assets/github.svg"
import Medium from "../../assets/medium.svg"
import Youtube from "../../assets/youtube.svg"
// @material-ui/icons

import styles from "../../jss/footerStyle.js"



const useStyles = makeStyles({
  ...styles,
  footerLink: {
    color: "#273746",
    "&:hover": {
      color: "#696666",
    },
  },
  linkStyle: {
    color: "#273746",
    padding: 10,
    "&:hover": {
      color: "#3c4858",
    },
  },
})

export default function Footer(props) {
  const classes = useStyles()
  const { whiteFont } = props
  const footerClasses = `
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
 `

 const [isShown, setIsShown] = useState(false);

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/seo-company-toronto/"
                href="https://atlasagency.ca/seo-company-toronto/"
                className={classes.block}
              >
                SEO
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/toronto-web-design/"
                href="https://atlasagency.ca/toronto-web-design/"
                className={classes.block}
              >
                Web Development
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/web-designer-toronto/"
                href="https://atlasagency.ca/web-designer-toronto/"
                className={classes.block}
              >
                Maintenance
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/portfolio/"
                href="https://atlasagency.ca/portfolio/"
                className={classes.block}
              >
                Portfolio
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/about-us-seo-company-toronto/"
                href="https://atlasagency.ca/about-us-seo-company-toronto/"
                className={classes.block}
              >
                About Us
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link
                to="/contact-us-web-design-toronto/"
                href="https://atlasagency.ca/contact-us-web-design-toronto/"
                className={classes.block}
              >
                Contact Us
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}></ListItem>
          </List>
        </div>
        <div>
          <div
            className={classes.right}
            style={{
              fontSize: "12px",
              fontWeight: "500",
              textTransform: "uppercase",
            }}
          >
            Copyright &copy; {1900 + new Date().getYear()} {""} |{" "}
            <a
              className={classes.footerLink}
              href="https://atlasagency.ca/"
              title="Atlas Agency"
            >
              {" "}
              Atlas Agency Inc. |{" "}
            </a>
            All rights reserved.
          </div>
          <Grid container justify="center" alignItems="center">
            <Grid item style={{ paddingLeft: 5 }}>
              <a
                href="https://twitter.com/AtlasAgencyCA"
                className={classes.linkStyle}
                title="twitter"
                target="_blank"
                rel="noopener noreferrer"
                id="twitter-link"
              >
            <Twitter   
                    onMouseEnter={() => setIsShown(true)} 
                    onMouseLeave={() => setIsShown(false)}     
                    fill={isShown ? "#273746" : "#3c4858" } 
                    stroke="white" 
                    height="30px" 
                    width="30px"  
                    title="Twitter"
                    id="twitter"
                    aria-labelledby="twitter-title"
                    aria-describedby="twitter-desc"
                    role="link"
                    tabIndex="0"
                    >
                  <title id="twitter-title">Our Twitter page</title>
                  <desc id="twitter-desc">Our Twitter Link</desc>
              </Twitter>
              </a>
              <a
                href="https://www.facebook.com/atlasagency.ca"
                rel="noopener noreferrer"
                title="facebook"
                target="_blank"
                id="facebook-link"
                className={classes.linkStyle}
              >
              <Facebook   
                    onMouseEnter={() => setIsShown(true)} 
                    onMouseLeave={() => setIsShown(false)}     
                    fill={isShown ? "#273746" : "#3c4858" } 
                    stroke="white" 
                    height="30px" 
                    width="30px"  
                    id="facebook"
                    title="Facebook"
                    aria-labelledby="facebook-title"
                    aria-describedby="facebook-desc"
                    role="link"
                    tabIndex="0"
                  >
                  <title id="facebook-title">Our Facebook page</title>
                  <desc id="facebook-desc">Our Facebook Link</desc>
                </Facebook>
              </a>
              <a
                href="https://medium.com/@atlasagency"
                className={classes.linkStyle}
                title="medium"
                id="medium-link"
              >
                <Medium   
                    onMouseEnter={() => setIsShown(true)} 
                    onMouseLeave={() => setIsShown(false)}     
                    fill={isShown ? "#273746" : "#3c4858" } 
                    stroke="white" 
                    id="medium"
                    height="30px" 
                    width="30px"  
                    title="Medium"
                    aria-labelledby="medium-title"
                    aria-describedby="medium-desc"
                    role="link"
                    tabIndex="0"
                    >
                  <title id="medium-title">Our Medium page</title>
                  <desc id="medium-desc">Our Medium Link</desc>
                  </Medium>
              </a>
              <a
                href="https://www.instagram.com/atlasagency.ca"
                className={classes.linkStyle}
                title="instagram"
                id="instagram-link"
              >
                <Instagram   
                    onMouseEnter={() => setIsShown(true)} 
                    onMouseLeave={() => setIsShown(false)}     
                    fill={isShown ? "#273746" : "#3c4858" } 
                    stroke="white" 
                    height="30px" 
                    width="30px"  
                    id="instagram"
                    title="Instagram"
                    aria-labelledby="instagram-title"
                    aria-describedby="instagram-desc"
                    role="link"
                    tabIndex="0"
                    >
                  <title id="instagram-title">Our Instagram page</title>
                  <desc id="instagram-desc">Our Instagram Link</desc>
                  </Instagram>
              </a>
              <a
                href="https://www.youtube.com/channel/UCBBOwDyYeV0pnTWkmQOLlFg"
                className={classes.linkStyle}
                title="youtube"
                id="youtube-link"
              >
                <Youtube   
                    onMouseEnter={() => setIsShown(true)} 
                    onMouseLeave={() => setIsShown(false)}     
                    fill={isShown ? "#273746" : "#3c4858" } 
                    stroke="white" 
                    id="youtube"
                    height="30px" 
                    width="30px"  
                    title="Youtube"
                    aria-labelledby="youtube-title"
                    aria-describedby="youtube-desc"
                    role="link"
                    tabIndex="0"
                    >
                  <title id="youtube-title">Our Youtube page</title>
                  <desc id="youtube-desc">Our Youtube Link</desc>
                </Youtube>
              </a>
              <a
                href="https://github.com/atlasagencyca"
                className={classes.linkStyle}
                title="github"
                id="github-link"
              >
               <GitHub   
                    onMouseEnter={() => setIsShown(true)} 
                    onMouseLeave={() => setIsShown(false)}     
                    fill={isShown ? "#273746" : "#3c4858" } 
                    name="Github"
                    stroke="white" 
                    id="github"
                    height="30px" 
                    width="30px"  
                    title="Github"
                    aria-describedby="github-desc"
                    aria-labelledby="github-title"
                    role="link"
                    tabIndex="0"
                    >
                  <title id="github-title">Our Github page</title>
                  <desc id="github-desc">Our Github Link</desc>
                </GitHub>
              </a>
            </Grid>
          </Grid>
        </div>
      </div>
    </footer>
  )
}
