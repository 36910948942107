/*eslint-disable*/
import React from "react"
import { Link } from "gatsby"

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Call from "@material-ui/icons/Call"

import styles from "../../jss/headerLinksStyle.js"

const useStyles = makeStyles(styles)

export default function HeaderLinks(props) {
  const classes = useStyles()
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <Link
          to="/toronto-web-design/"
          href="https://atlasagency.ca/toronto-web-design/"
          className={classes.dropdownLink}
        >
          <b>WEB DEVELOPMENT</b>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/seo-company-toronto/"
          className={classes.dropdownLink}
          href="https://atlasagency.ca/seo-company-toronto/"
        >
          <b>SEO</b>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/web-designer-toronto/"
          href="https://atlasagency.ca/web-designer-toronto/"
          className={classes.dropdownLink}
        >
          <b>MAINTENANCE</b>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/portfolio/"
          href="https://atlasagency.ca/portfolio/"
          className={classes.dropdownLink}
        >
          <b>PORTFOLIO</b>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/about-us-seo-company-toronto/"
          href="https://atlasagency.ca/about-us-seo-company-toronto/"
          className={classes.dropdownLink}
        >
          <b>ABOUT US</b>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link
          to="/contact-us-web-design-toronto/"
          href="https://atlasagency.ca/contact-us-web-design-toronto/"
          className={classes.dropdownLink}
        >
          <b>CONTACT US</b>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <div style={{ position: "relative", top: "-5px" }}>
          <a href="tel:647-371-0263" className={classes.dropdownLink}>
            <Call style={{ position: "relative", right: "10px" }} />
            <b style={{ position: "relative", top: "-5px" }}>647-371-0263</b>
          </a>
        </div>
      </ListItem>
    </List>
  )
}
