import React from "react"

// nodejs library that concatenates classes

// nodejs library to set properties for components

// @material-ui/core components

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"
import Grid from "@material-ui/core/Grid"
import { Link } from "gatsby"
import Hidden from "@material-ui/core/Hidden"
import Drawer from "@material-ui/core/Drawer"
// @material-ui/icons
import Menu from "@material-ui/icons/Menu"
import Call from "@material-ui/icons/Call"
// core components
import { makeStyles } from "@material-ui/core/styles"

import styles from "../../jss/headerStyle.js"
import Logo from "../../assets/apple-icon.svg"

const useStyles = makeStyles(styles)

export default function Header(props) {
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange)
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange)
      }
    }
  })

  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props
    const windowsScrollTop = window.pageYOffset
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color])
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color])
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color])
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color])
    }
  }

  const { rightLinks, leftLinks } = props

  const brandComponent = (
    <div style={{ padding: "15px" }}>
      <Link
        to="/"
        href="https://atlasagency.ca/"
        aria-label="Our Company Logo"
        alt="logo-toronto-web-design"
      >
        <Logo
          alt="web-design-toronto"
          style={{ maxWidth: "50px" }}
          id="Logo"
          aria-labelledby="logo-title"
          aria-describedby="logo-desc"
        >
          <title id="logo-title">Our Logo</title>
          <desc id="logo-desc">Our Logo Link</desc>
        </Logo>
      </Link>
    </div>
  )

  return (
    <AppBar
      position="fixed"
      style={{ backgroundColor: "transparent", boxShadow: "none" }}
    >
      <Toolbar className={classes.container}>
        <Grid container>
          <Grid item xs={10} lg={2} md={3} sm={11}>
            <Link to="/" href="https://goproplubming.ca" aria-label="Logo">
              <Logo width="35%" height="100%" aria-label="Logo" />
            </Link>
          </Grid>
          <Grid item xs={11} lg={10} md={12} sm={11}>
            <Hidden smDown implementation="js">
              <div className={classes.collapse}>{rightLinks}</div>
            </Hidden>
          </Grid>
        </Grid>

        <Hidden mdUp>
          <Grid container direction="row" className={classes.titleTablet}>
            <Grid item xs={12}>
              <IconButton
                style={{ color: "white" }}
                aria-label="open drawer"
                onClick={handleDrawerToggle}
              >
                <Menu />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <a href="tel:647-371-0263">
                <Call style={{ position: "relative", right: "10px" }} />
              </a>
            </Grid>
          </Grid>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          transitionDuration={{ enter: 470, exit: 470 }}
          anchor={"top"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.appResponsive}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                marginLeft: "auto",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="close drawer"
                onClick={handleDrawerToggle}
              >
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
            </div>
          </div>
          {rightLinks}
        </Drawer>
      </Hidden>
    </AppBar>
  )
}
